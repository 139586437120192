<template>
    <div class="contents">
        <div style="display: flex;justify-content: space-between;align-items: center;">
            <h1 style="height: 50px;line-height: 50px;margin-top:50px;">{{ $t("top-select-ai-live-data-detail-ch") }}</h1>
            <button @click="$router.go(-1)" class="back-btn">목록으로 가기</button>
        </div>
        <div class="flexB" style="display: flex; align-items: stretch;margin-bottom: 15px;">
            <div class="video-list" style="position: relative; width:60%;">
                <div v-if="liveData != null">
                    <WebRTC_WOWZA
                                class="play-on play-3-view"
                                v-if="liveData != null" 
                                v-show="liveData.isLoad == true"
                                :ref="`video_${liveData.channelId}`"
                                :sdpUrl="liveData.streamServer.properties.sdpUrl"
                                :applicationName="liveData.streamServer.properties.aiApplication"
                                :streamName="liveData.linkedAiId+liveData.streamServer.properties.streamPostfix"                            
                                :playerId="liveData.channelId"
                                :selectStreamId ="selectedSoundId"
                                :channelIndex="1"
                                :deviceType="liveData.assignedDeviceType"
                                :isSpeakerTc ="false"
                                @toggleSound ="toggleSound"
                                @isLoadedmetadata="playerLoadData"
                                @playerOpen="playerOpen"
                                @playerRemoveId="playerRemove"
                            />
                          <!-- <Video
                            :ref="`video_${liveData.channelId}`"
                            v-if="liveData != undefined" v-show="liveData.isLoad == true"
                            class="play-on play-3-view" 
                            :src="liveData.aiLiveURL"
                            :isVod="false"
                            :isAuto="true"
                            :isControls="false"
                            :playerId="liveData.channelId"
                            :selectStreamId ="selectedSoundId"
                            :channelIndex="1"
                            :deviceType="liveData.assignedDeviceType"
                            @toggleSound ="toggleSound"
                            @isLoadedmetadata="playerLoadData"
                            @playerOpen="playerOpen" /> -->

                            <!-- webRtc streamKey -->
                            <!-- :streamName= liveData.linkedAiId+liveData.streamServer.properties.streamPostfix  -->
                            <!-- :streamName="liveData.streamKey"  -->

                             <!-- hsl streamKey -->
                            <!-- :src="liveData.aiLiveURL" -->
                            <!-- :src="getLiveUrl(liveData)" -->
                </div>
                <div v-else>
                    <div v-show="liveData == undefined || liveData.isLoad == false" class="play-off">
                      <img src="@/assets/images/play-off-icon.png" alt="icon" />
                      <h2>영상 신호 없음</h2>
                    </div>
                </div>
            </div>
            <div class="ai-live box m-b-0">
                <div class="mapWrap" style="width:100%; height:338px; padding:0px;" >
                    <div class="map" style="height: 100%;">
                        <Map ref="map" style="width: 100%; height: 100%; border: 1px solid #d3d3d3;" 
                            :loadMapName="loadMap"
                            :isLeftButtonPosition="false"/>
                            <MapButtonComponent ref="buttonMap" />
                    </div>
                </div>
                <div class="ai-live-info">
                    <div class="ai-info-top">
                        <div class="ai-drone-info">
                            <div class="ai-emphysema-img">
                                <div class="drone-image" v-if="liveData != undefined && liveData.assignedDeviceType != 'Mobile'&& liveData.isLoad == true">
                                    <img :src="getDeviceImg(liveData)" alt="icon" />
                                </div>
                                <div class="phone-image" v-if="liveData != undefined && liveData.assignedDeviceType === 'Mobile'&& liveData.isLoad == true">
                                    <img src="@/assets/images/phone-img.png" alt="icon" />
                                </div>
                            </div>
                            <div class="ai-drone-value">
                                <h2>{{liveData == undefined || liveData.isLoad == false ? ' - ' : liveData.assignedDeviceType != 'Mobile' ? liveData.assignedDeviceName : liveData.assignedDeviceType}}</h2>
                                <h4>
                                    <span>{{liveData == undefined || liveData.isLoad == false ? ' - ' : liveData.assignedDepartmentName}}</span>│
                                    <span>{{liveData == undefined || liveData.isLoad == false ? ' - ' : liveData.assignedDeviceType != 'Mobile' ? liveData.assignedUserName : liveData.assignedUserName }}</span>
                                </h4>
                            </div>
                        </div>
                        <div class="ai-battery-info">
                            <h4>
                                <span>{{liveData == undefined || liveData.isLoad == false || liveData.battery== undefined ? ' - ' : liveData.battery}}</span>
                                <span>%</span>
                            </h4>
                            <ul>
                                <li v-for="(b_index) in 5" :key="'battery' + b_index"  :class=" liveData == undefined || liveData.isLoad == false || liveData.battery ==undefined  ? 'off' : (5-b_index)*20 <= liveData.battery ? 'on' : 'off' " />
                            </ul>
                        </div>
                    </div>
                    <div class="ai-value-info">
                        <div class="ai-video-value">
                            <div class="ai-value-box m-l-0">
                                <h4>고도</h4>
                                <h2><span>{{ liveData == undefined || liveData.isLoad == false || liveData.height == null ? '-' : `${liveData.height.toFixed(0)} ` }}</span>m</h2>
                            </div>
                            <div class="ai-value-box">
                                <h4>거리</h4>
                                <h2><span>{{ liveData == undefined || liveData.isLoad == false || liveData.distance == null ? '-' : `${liveData.distance.toFixed(0)} ` }} </span>m</h2>
                            </div>
                            <div class="ai-value-box">
                                <h4>수평</h4>
                                <h2><span>{{ liveData == undefined || liveData.isLoad == false || liveData.hspeed == null ? '-' : `${liveData.hspeed.toFixed(0)} ` }}</span>m/s</h2>
                            </div>
                            <div class="ai-value-box">
                                <h4>수직</h4>
                                <h2><span>{{ liveData == undefined || liveData.isLoad == false || liveData.hspeed == null ? '-' : `${liveData.hspeed.toFixed(0)} ` }}</span>m/s</h2>
                            </div>
                        </div>
                        <div class="ai-video-coordinate">
                            <div class="ai-coordinate-row m-l-0">
                                <h4>위도</h4>
                                <h2><span>{{ liveData == undefined || liveData.isLoad == false || liveData.lat == null ? '-'   :  `${liveData.lat.toFixed(9)} ` }} </span></h2>
                            </div>
                            <div class="ai-coordinate-row">
                                <h4>경도</h4>
                                <h2><span>{{ liveData == undefined || liveData.isLoad == false || liveData.lng == null ? '-' : `${liveData.lng.toFixed(9)} ` }} </span></h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="ai-detecting-list box m-b-0">
            <h2>AI 탐지 객체 정보</h2>
            <div class="ai-detecting-content">
                <div class="detecting-box">
                    <div class="detecting-img" v-if="liveData != undefined && liveData.isLoad == true &&  lastResultImgUrl != null" >
                        <img :src="lastResultImgUrl" alt="icon" />
                    </div>
                </div>
                <div class="ai-detecting-table">
                    <table>
                        <tr>
                            <th>탐지 대상</th>
                            <th>정확도</th>
                            <th>이미지 번호</th>
                            <th>x1</th>
                            <th>x2</th>
                            <th>y1</th>
                            <th>y2</th>
                        </tr>
                        <tr v-for="data in aiLastResult" :key="data.id">
                            <td>{{data.class_name}}</td>
                            <td>{{percentage(data.confidence)}}</td>
                            <td>{{data.current_frame}}</td>
                            <td>{{data.x1}}</td>
                            <td>{{data.x2}}</td>
                            <td>{{data.y1}}</td>
                            <td>{{data.y2}}</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>

    </div>
</template>
<style>
.title{display: none;}
.back-btn{
    margin-top: 50px;
    border: 1px solid #ddd;
    padding: 0 30px;
    background: #fff;
    color: #454545;
    border-radius: 5px;
}
.m-l-0{margin-left: 0 !important;}
.m-b-0{margin-bottom: 0 !important;}
.ai-live{width: 39%;}
.ai-live-info{margin-top: 15px;}
.ai-info-top{display: flex;justify-content: space-between;align-items: center;padding-bottom: 15px;border-bottom: 1px solid #ddd;}
.ai-drone-info{display: flex;align-items: center;}
.ai-emphysema-img{margin-right: 10px;}
.ai-emphysema-img > .drone-image{width: 100px;height: 70px;text-align: center;background: #eee;border-radius: 5px;padding: 5px;}
.ai-emphysema-img > .drone-image > img{height: 100%;}
.ai-emphysema-img > .phone-image{width: 100px;height: 70px;text-align: center;background: #eee;border-radius: 5px;padding: 5px;}
.ai-emphysema-img > .phone-image > img{height: 100%;}

.ai-drone-value > h2{font-size: 2rem;font-weight: bold;margin-bottom: 5px;}
.ai-drone-value > h4{font-size: 1.4rem;font-weight: 400;}

.ai-battery-info{display: flex;align-items: center;}
.ai-battery-info > h4{font-size: 1.4rem;font-weight: 400;}
.ai-battery-info > ul{display: flex;margin-bottom: 0px;margin-left: 10px;}
.ai-battery-info > ul > li{height: 25px;width: 8px;margin-right: 2px;border-radius: 2px;}
.ai-battery-info > ul > li.off{background: #c9c9c9;}
.ai-battery-info > ul > li.on{background: #20ECC9;}

.ai-value-info{padding-top: 15px;}
.ai-video-value{display: flex;justify-content: space-between;align-items: center; margin-bottom: 15px;}
.ai-value-box{width: 25%;display: flex;justify-content: space-between;align-items: center;margin-left: 10px;}
.ai-value-box > h4{background: #eee;color: #969696;padding: 3px 15px;border-radius: 5px;font-size: 1.2rem;font-weight: 400;}
.ai-value-box > h2{font-size: 1.4rem;margin: 0;font-weight: bold;}

.ai-video-coordinate{display: flex;justify-content: space-between;align-items: center;}
.ai-coordinate-row{width: 50%;display: flex;justify-content: space-between;align-items: center;margin-left: 10px;}
.ai-coordinate-row > h4{background: #eee;color: #969696;padding: 3px 15px;border-radius: 5px;font-size: 1.2rem;font-weight: 400;}
.ai-coordinate-row > h2{margin: 0;font-size: 1.4rem;font-weight: bold;}

.ai-detecting-list > h2{font-size: 1.4rem;margin: 0;font-weight: bold;margin-bottom: 15px;}
.ai-detecting-content{display: flex;justify-content: space-between;align-items: flex-start;}
.detecting-box{width: 365px;height: 200px; background: #eee;}
.detecting-img{width: 365px;height: 200px;}
.detecting-img > img{width: 100%;height: 100%;}

.ai-detecting-table{width: calc(100% - 390px);height: 200px;max-height: 200px;overflow-y: auto;}
</style>
<script>
import moment from "moment";
import Map from "@/components/map/Map";
import MapLineData from "@/components/map/data/MapLineData";
import MapDataType from "@/components/map/enum/MapDataType";
import MapMarkerData from "@/components/map/data/MapMarkerData";
import {getMobileImgDefault, getDeviceImgUrl, getDeviceImgDefault} from "@/api/device"

import Video from "@/components/video/videoPlayer"; Video
import {fetchLocation} from "@/api/location";
import {playerPopOpen2} from "@/util/popup";
import { fetchEvent } from "@/api/event";
import { fetchPointList, getPointTypeName } from "@/api/point";
import { mapState } from "vuex";

import { ko } from "vuejs-datepicker/dist/locale";
import { fetchLastResult,fetchResults,fetchLastResultImage } from "@/api/ai"; 

MapLineData
import { fetchChannel,getLiveUrl } from "@/api/channel";
import WebRTC_WOWZA from "@/components/video/WebRTC_WOWZA";
import MapButtonComponent from "@/components/map/MapButtonComponent.vue";
let deviceTypeName = "DEVICE";
let supportTypeName = "SUPPORT";
let pointTypeName = "POINT";
let noticeTypeName = "NOTICE";

export default {
    components: { Map,WebRTC_WOWZA, MapButtonComponent},
    name: "AiVodDetail",

    data() {
        return {
            //index: 0,
            moment: moment,
            selectedSoundId: null,
            loadMap: 'kakao',
            viewSrc: "",
            vodUrl: "",

            isdrModelShow: true,
            eventId: null,
            eventInfo: null,
            contentsId: null,
            vodObj: {},
            
            locationList : [],
            lat: null,
            lng: null,
            locationSize: null,
            locationPage : 0,
            isPlayStatus: false,
           
            ko: ko,
            liveData : null,
            windowWidth: null,
            isFirstMove : false,
            checkBoxType: [deviceTypeName, supportTypeName, pointTypeName],
            locationInterval : null,
            showFourthDiv:false,
            aiLastResult : null,
            aiResult : null,
            aiResultPage: 0,
            lastResultImgUrl : null,
            aiConfidence : 0.7,
            isFirst : true,
            isAiResultTaks : true,
        };
    },
    computed: {
        ...mapState({
            currentLang: state => state.store.currentLang,
            userType: state => state.store.userType,
            accountId: state => state.store.accountId,
        }),
        deviceTypeName : function(){
            return deviceTypeName;
        },
        supportTypeName : function(){
            return supportTypeName;
        },
        pointTypeName : function(){
            return pointTypeName;
        },
        noticeTypeName : function(){
            return noticeTypeName;
        },
        
    },
    watch: {
        currentLang(value) {
            this.initMapData(value);
        },
    },
    mounted() {
        this.$refs.map.isShowLine = true
        this.getEvent();
        this.initMapData(this.$store.state.store.currentLang);
        this.getChannel()
        this.initButtonMap()

        this.locationInterval = setInterval( ()=>{
             this.getLocation(this.liveData)
             this.getAILastResult()
             this.getLastResultImg()
            if(this.isAiResultTaks){
                 this.getAiResult()
            }
        },2000)       
    },
    created() {
        this.channelId = this.$route.query.channelId
       if (this.eventId == null && this.$route.params.eventId != undefined) {
            this.eventId = this.$route.params.eventId
        }
    },
    methods: {
        initButtonMap() {
            this.$refs.buttonMap.showMapTypeChange()
            this.$refs.buttonMap.showMapCenter()
        },
        playerLoadData(id, isLoad) {
            this.$set(this.liveData, "isLoad", isLoad);
        },
        initMapData(value) {
            if (value == 'en') {
                this.loadMap = 'google'
            } else {
                this.loadMap = 'kakao'
            }
            this.getEventPoint();
            
            this.handleShowType();

            if (this.contentsId != null) {
                this.getLocation(this.contentsId);
            }
        },
        getEvent() {
            if (this.eventId == undefined) {
                return;
            }
            fetchEvent(this.eventId).then((res) => {
                if (res.data.result == 0) {
                    this.eventInfo = res.data.data;
                    if (this.$refs.map == undefined) {
                        return;
                    }
                    if (this.eventInfo.lng != null && this.eventInfo.lng != "" && this.eventInfo.lat != null && this.eventInfo.lat != "") {
                        this.$refs.map.onLngLatToMoveCenter(this.eventInfo.lng, this.eventInfo.lat)
                        this.$refs.map.onCreateMapMarker(this.eventId, this.eventInfo.lng, this.eventInfo.lat, MapDataType.ID_SITE);
                        this.$refs.map.onSetLngLat(this.eventInfo.lng, this.eventInfo.lat);
                    } else {
                        this.$refs.map.onAddressToMoveCenter(this.eventInfo.address);
                    }
                }
            });
        },
        getEventPoint() {
            let params = {
                eventId: this.eventId,
                serverTime: null,
            };
            fetchPointList(params).then((res) => {
                if (res.data.result == 0) {
                    this.pointServerTime = res.data.data.serverTime
                    this.pointCount = res.data.data.total
                    this.pointList = res.data.data.content
                    this.pointList.forEach((element) => {
                        element.selectId = element.index;
                        element.selectType = "Point";
                        element.isOwner = false;
                        let pointMapMarker = new MapMarkerData();
                        element.name = this.$t(getPointTypeName(element.type));
                        pointMapMarker.setPointData(element);
                        this.$refs.map.onCreateMarker(MapDataType.ID_POINT, pointMapMarker);
                    })
                }
            });
        },
        customFormatter(date) {
            return moment(date).format("YYYY년 MM월 DD일");
        },
        getChannel(){
            fetchChannel(this.channelId).then((res) => {
                if(res.data.result ==0){
                    this.liveData = res.data.data    
                    console.log("this.liveData" , this.liveData)
                    this.$forceUpdate();
                }
            }).then(()=>{
                this.getLocation(this.liveData)
                this.getAILastResult()
                this.getAiResult()
            })
        },
        async getLocation(data) {
            if (data == null || data == undefined) {
                return;
            }
            data.selectId = data.assignedDeviceType === 'Drone' ? data.assignedDeviceLogId : data.assignedWorkerId ;
            data.name = data.assignedDeviceType === 'Drone' ? data.assignedDeviceName : data.assignedUserName ;
            var isMain = data.assignedDeviceType === 'Mobile' ? false : true

            var mapDataType = this.getSelectTypeToMapDataType(data.assignedDeviceType);
            let params = {
                refId: data.selectId,
                eventId: this.eventId,
                offsetIndex: 0, //this.$refs.map.onGetLineSize(mapDataType, data.selectId),
            };

            fetchLocation(params).then((res) => {
                if (res.data.result == 0) {
                if (res.data.data.content.length > 0) {
                    this.locationList = res.data.data.content.sort(function (a, b) {
                        return a.index - b.index;
                    })
                    var location = res.data.data.content[res.data.data.content.length - 1];
                    if(mapDataType == MapDataType.ID_DRONE) {
                        data.distance = location.distance;
                        data.gimbalDirection = location.gimbalDirection;
                        data.height = location.height;
                        data.rotate = location.azimuth;
                        data.battery = location.battery
                        data.hspeed = location.hspeed
                        data.vspeed = location.vspeed
                     
                        //드론 처음 위치 정보 가져오면 그때 이동 (한번만 이동)
                        if(this.isFirstMove == false) {
                            this.isFirstMove = true;
                            this.$refs.map.onLngLatToMoveCenter(location.lng, location.lat);
                        }
                    }
                    data.lat = location.lat;
                    data.lng = location.lng;
                    if(data.lat != undefined && data.lng != undefined){
                        this.changeMapLatLng(data.lat, data.lng);
                    }else{
                        this.changeMapLatLng(this.eventInfo.lat, this.eventInfo.lng);
                    }
                    this.createMapMarker(mapDataType, data, isMain);
                    this.createMapLine(mapDataType, data, res.data.data.content);
                    this.$forceUpdate();
                }
                }
            })
        },
        playerRemove(id){
            id
            // this.liveData = null
            // clearInterval(this.locationInterval);
            alert("streaming이 종료되었습니다.")
        },
        toggleSound(channelId){
            this.selectedSoundId = channelId
         },
         playerOpen(type,id,index) {
            playerPopOpen2(type, id, index);
        },
        getSelectTypeToMapDataType(selectType){
            var mapDataType = null;
            if(selectType == null || selectType == undefined) {
                return mapDataType;
            }

            switch (selectType){
                case "Drone" :
                mapDataType = MapDataType.ID_DRONE;
                break;
                case "Worker" :
                mapDataType = MapDataType.ID_USER;
                break;
                case "Mobile" :
                mapDataType = MapDataType.ID_STREAMER;
                break;
                case "Controller" :
                mapDataType = MapDataType.ID_CONTROLLER;
                break;
                case "Point" :
                mapDataType = MapDataType.ID_POINT;
                break;
                case "Station" :
                mapDataType = MapDataType.ID_STATION;
                break;
            }
            return mapDataType;
        },
        changeMapLatLng(lat, lng){
            if(lat == null || lat == undefined || lng == null || lng == undefined) {
                return;
            }

            this.$refs.map.onSetLngLat(lng, lat);
        },
        createMapMarker(mapDataType, data, isMain) {
            if(this.$refs.map == undefined) {
                return;
            }
            let mapMarkerData = new MapMarkerData();
            mapMarkerData.id = data.selectId;
            mapMarkerData.isMain = isMain;
            switch (mapDataType) {
                case MapDataType.ID_SITE :
                    mapMarkerData.lat = data.lat;
                    mapMarkerData.lng = data.lng;
                    break;

                case MapDataType.ID_DRONE :
                    mapMarkerData.setDroneData(data);
                    break;

                case MapDataType.ID_USER:
                case MapDataType.ID_STREAMER:
                case MapDataType.ID_CONTROLLER:
                    mapMarkerData.setUserData(data);
                    break;

                case MapDataType.ID_STATION:
                mapMarkerData.setStationData(data);
                    break;

                case MapDataType.ID_MAP_AI_MARKER :
                    mapMarkerData.lat = data.lat;
                    mapMarkerData.lng = data.lng;
                    mapMarkerData.name = data.confidence //가장큰 정확도 
                    break;
            }

            if (mapDataType == MapDataType.ID_USER) {
                var filtr = this.channelList.filter(item => {
                return item.assignedWorkerId == data.selectId;
                })
                if (filtr.length > 0) {
                mapMarkerData.isUserLive = true;
                }
            }

            if (this.$refs.map.onIsMarker(mapDataType, data.selectId)) {
                this.$refs.map.onModifyMarkerAt(mapDataType, mapMarkerData);
            } else {
                this.$refs.map.onCreateMarker(mapDataType, mapMarkerData);
            }
        },
        createMapLine(mapDataType, data, locationGroup) {
            var mapLineData = new MapLineData();
            mapLineData.id = data.selectId;
            mapLineData.locationGroup = locationGroup;
            if (this.$refs.map.onIsLine(mapDataType, data.selectId)) {
                this.$refs.map.onModifyLineAddAt(mapDataType, mapLineData);
            } else {
                this.$refs.map.onCreateLine(mapDataType, mapLineData);
            }
        },
        handleShowType() {
            if(this.$refs.map == undefined) {
                return;
            }

            if (this.checkBoxType.includes(this.deviceTypeName)) {
                this.$refs.map.onSetIsShowDataType(MapDataType.ID_DRONE, true);
            } else {
                this.$refs.map.onSetIsShowDataType(MapDataType.ID_DRONE, false);
            }
            if (this.checkBoxType.includes(this.supportTypeName)) {
                this.$refs.map.onSetIsShowDataType(MapDataType.ID_CONTROLLER, true);
            } else {
                this.$refs.map.onSetIsShowDataType(MapDataType.ID_CONTROLLER, false);
            }
            if (this.checkBoxType.includes(this.supportTypeName)) {
                this.$refs.map.onSetIsShowDataType(MapDataType.ID_USER, true);
            } else {
                this.$refs.map.onSetIsShowDataType(MapDataType.ID_USER, false);
            }
            if (this.checkBoxType.includes(this.pointTypeName)) {
                this.$refs.map.onSetIsShowDataType(MapDataType.ID_POINT, true);
            } else {
                this.$refs.map.onSetIsShowDataType(MapDataType.ID_POINT, false);
            }
            if (this.checkBoxType.includes(this.pointTypeName)) {
                this.$refs.map.onSetIsShowDataType(MapDataType.ID_STREAMER, true);
            } else {
                this.$refs.map.onSetIsShowDataType(MapDataType.ID_STREAMER, false);
            }
        },
        getLiveUrl(channelData) {
            var url = getLiveUrl(channelData);
            if (url == '-') {
                return ''
            }
            return url
        },
        getAILastResult(){
            var params = {
                media_key : this.liveData.linkedAiId,
                conf :  0.1
            }
            fetchLastResult(params).then((res)=>{
                if(res.request.status == 200){
                    if(res.data.length != 0){
                        this.aiLastResult = res.data[0].bboxes
                    }
                }
            }).catch(() => {
                //console.error("마지막 디텍팅 결과를 불러오는데 실패했습니다.", error);
            });
        },
        getAiResult(){
            //let now = new Date();
            //console.log("getAiResult Start" ,now.getHours() + ':' + now.getMinutes() + ':' + now.getSeconds());
            this.isAiResultTaks = false
            var params = {
                media_key : this.liveData.linkedAiId,
                conf :  this.aiConfidence
            }
            fetchResults(params).then((res)=>{
                if(res.request.status == 200){
                    if(res.data.length != 0){
                        this.aiResult = res.data
                    }
                    this.findAiMaxConfidence();
                }
            }).catch(() => {
               // console.error("디텍팅 전체 결과를 불러오는데 실패했습니다.", error);
            }).finally(()=>{
                this.isAiResultTaks = true
                //console.log("getAiResult End" ,now.getHours() + ':' + now.getMinutes() + ':' + now.getSeconds());
            });
        },
        getDeviceImg(data){
        if(data.assignedDeviceType == "Mobile"){
            return getMobileImgDefault(data.assignedDeviceType);
        }
        var img = data.assignedDeviceDefaultIsImage ? getDeviceImgDefault(data.assignedDeviceManufacturerId, data.assignedDeviceModelId) : getDeviceImgUrl(data.accountId, data.assignedDeviceId) + '?date='+(new Date)
        return img
        },
        getLastResultImg(){
            let params = {
                media_key : this.liveData.linkedAiId,
            }
            fetchLastResultImage(params).then(res=>{
                var blob = new Blob([res.data])
                this.lastResultImgUrl = URL.createObjectURL(blob);
            })
            .catch(() => {
            //console.error("이미지를 불러오는데 실패했습니다.", error);
            });
        },
        percentage(value) {
            return `${(value * 100).toFixed(1)}%`;
        },
        findAiMaxConfidence(){
          
            // var t1 = moment(this.aiResult[this.aiResult.length-1].event_time).add(9, 'hours').format("YYYY:MM:DD HH:mm:ss");
            // var t2 = moment(this.locationList[this.locationList.length-1].time).format("YYYY:MM:DD HH:mm:ss");
            // console.log("시간" , t1, t2)
            var locationSize = this.locationList.length
            var aiResultSize = this.aiResult.length;
            for (var k = this.locationPage; k < locationSize; k++) {
                var item = this.locationList[k]
                var locationTime = moment(item.time).format("YYYY:MM:DD HH:mm:ss");
                var aiTempList = [];
              
                for (var i = this.aiResultPage; i < aiResultSize; i++) {
                    var ai = this.aiResult[i];
                    var aiTime = moment(ai.event_time).add(9, 'hours').format("YYYY:MM:DD HH:mm:ss");

                    if (locationTime == aiTime) {
                        // bboxes를 confidence 순서대로 정렬
                        ai.bboxes.sort((a, b) => b.confidence - a.confidence);

                        // confidence 값을 변환
                        ai.confidence = this.percentage(ai.bboxes[0].confidence);
                        ai.lat = item.lat;
                        ai.lng = item.lng;
                        ai.selectId = locationTime;

                        // 조건을 만족하는 ai 객체를 aiTempList에 추가
                        aiTempList.push(ai);
                    }
                }
                this.aiResultPage = aiResultSize -1
                if(aiTempList.length != 0){
                    aiTempList.sort((a,b) => {
                        return b.confidence - a.confidence
                    })
                    this.createMapMarker(MapDataType.ID_MAP_AI_MARKER ,aiTempList[0],true )
                    if(this.isFirst){
                        this.$refs.map.onLngLatToMoveCenter(aiTempList[0].lng, aiTempList[0].lat)
                        this.isFirst = false
                    }
                   
                }
            }
            this.locationPage = locationSize - 1 
        },
    },
    beforeDestroy() {
        clearInterval(this.locationInterval);
    },

};
</script>

<style>

</style>